import React, {useState, useEffect} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import {StaticImage} from "gatsby-plugin-image";
import {graphql} from 'gatsby';

import swatchBrushed from "../../../../images/shared/circle-brushed-nickle.png";
import swatchBlack from "../../../../images/shared/circle-matte-black.png";
import BANManagement from "../../../../components/pages/BANManagement";
import FaqSection from "../../../../components/pages/FaqSection";
import ProductDocuments from "../../../../components/products/ProductDocuments";

import iconVertStndBotKey from "../../../../images/shared/lock-icons/versa/icon-versa-vert-standard-bottom-keypad.svg";
import iconVertStndBotKeyOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-standard-bottom-keypad-on.svg";
import iconVertStndTopKey from "../../../../images/shared/lock-icons/versa/icon-versa-vert-standard-top-keypad.svg";
import iconVertStndTopKeyOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-standard-top-keypad-on.svg";
import iconVertMiniBotKey from "../../../../images/shared/lock-icons/versa/icon-versa-vert-mini-bottom-keypad.svg";
import iconVertMiniBotKeyOn from "../../../../images/shared/lock-icons/versa/icon-versa-vert-mini-bottom-keypad-on.svg";
import iconVertMiniTopKey from "../../../../images/shared/lock-icons/versa/icon-versa-vert-mini-top-keypad.svg";
import iconVertMiniTopKeyOn from "../../../../images/shared/lock-icons/versa/icon-versa-vert-mini-top-keypad-on.svg";
import iconHorizStndLeftKey from "../../../../images/shared/lock-icons/versa/icon-versa-horz-standard-left-keypad.svg";
import iconHorizStndLeftKeyOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-horz-standard-left-keypad-on.svg";
import iconHorizStndRightKey from "../../../../images/shared/lock-icons/versa/icon-versa-horz-standard-right-key.svg";
import iconHorizStndRightKeyOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-horz-standard-right-key-on.svg";
import iconHorizMiniLeftKey from "../../../../images/shared/lock-icons/versa/icon-versa-horz-mini-left-keypad.svg";
import iconHorizMiniLeftKeyOn from "../../../../images/shared/lock-icons/versa/icon-versa-horz-mini-left-keypad-on.svg";
import iconHorizMiniRightKey from "../../../../images/shared/lock-icons/versa/icon-versa-horz-mini-right-keypad.svg";
import iconHorizMiniRightKeyOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-horz-mini-right-keypad-on.svg";


import iconVertStndBotRfid from "../../../../images/shared/lock-icons/versa/icon-versa-vert-standard-bottom-rfid.svg";
import iconVertStndBotRfidOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-standard-bottom-rfid-on.svg";
import iconVertStndTopRfid from "../../../../images/shared/lock-icons/versa/icon-versa-vert-standard-top-rfid.svg";
import iconVertStndTopRfidOn from "../../../../images/shared/lock-icons/versa/icon-versa-vert-standard-top-rfid-on.svg";
import iconVertMiniBotRfid from "../../../../images/shared/lock-icons/versa/icon-versa-vert-mini-bottom-rfid.svg";
import iconVertMiniBotRfidOn from "../../../../images/shared/lock-icons/versa/icon-versa-vert-mini-bottom-rfid-on.svg";
import iconVertMiniTopRfid from "../../../../images/shared/lock-icons/versa/icon-versa-vert-mini-top-rfid.svg";
import iconVertMiniTopRfidOn from "../../../../images/shared/lock-icons/versa/icon-versa-vert-mini-top-rfid-on.svg";
import iconHorizStndLeftRfid from "../../../../images/shared/lock-icons/versa/icon-versa-horz-standard-left-rfid.svg";
import iconHorizStndLeftRfidOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-horz-standard-left-rfid-on.svg";
import iconHorizStndRightRfid from "../../../../images/shared/lock-icons/versa/icon-versa-horz-standard-right-rfid.svg";
import iconHorizStndRightRfidOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-horz-standard-right-rfid-on.svg";
import iconHorizMiniLeftRfid from "../../../../images/shared/lock-icons/versa/icon-versa-horz-mini-left-rfid.svg";
import iconHorizMiniLeftRfidOn from "../../../../images/shared/lock-icons/versa/icon-versa-horz-mini-left-rfid-on.svg";
import iconHorizMiniRightRfid from "../../../../images/shared/lock-icons/versa/icon-versa-horz-mini-right-rfid.svg";
import iconHorizMiniRightRfidOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-horz-mini-right-rfid-on.svg";
import iconVertHybridBotKey
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-bottom-keypad-N.svg";
import iconVertHybridBotKeyOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-bottom-keypad-N-on.svg";
import iconVertHybridTopKey from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-top-keypad-N.svg";
import iconVertHybridTopKeyOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-top-keypad-N-on.svg";
import iconHorizHybridLeftKey
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-left-keypad-N.svg";
import iconHorizHybridLeftKeyOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-left-keypad-N-on.svg";
import iconHorizHybridRightKey
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-right-keypad-N.svg";
import iconHorizHybridRightKeyOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-right-keypad-N-on.svg";

import iconVertHybridBotRfid from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-bottom-rfid-N.svg";
import iconVertHybridBotRfidOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-bottom-rfid-N-on.svg";
import iconVertHybridTopRfid from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-top-rfid-N.svg";
import iconVertHybridTopRfidOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-top-rfid-N-on.svg";
import iconHorizHybridLeftRfid from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-left-rfid-N.svg";
import iconHorizHybridLeftRfidOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-left-rfid-N-on.svg";
import iconHorizHybridRightRfid
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-right-rfid-N.svg";
import iconHorizHybridRightRfidOn
  from "../../../../images/shared/lock-icons/versa/icon-versa-vert-hybrid-right-rfid-N-on.svg";


import flatCam from "../../../../images/shared/cams/flat.png";
import lifterCam from "../../../../images/shared/cams/lifter.png";
import offsetCam from "../../../../images/shared/cams/offset.png";
import slamCam from "../../../../images/shared/cams/slam.png";


import BookConsultation from "../../../../components/pages/BookConsultation";


const IndexPage = () => {

  const {t} = useTranslation();

  //Interface choices
  const [isRfidSelected, setIsRfidSelected] = useState(false);
  const [isKeypadSelected, setIsKeypadSelected] = useState(true);
  const [isSmartphoneKeypadSelected, setIsSmartphoneKeypadSelected] = useState(false);
  const [isSmartphoneRfidSelected, setIsSmartphoneRfidSelected] = useState(false);

  // Body Style
  const [bodyStyleLocks1, setBodyStyleLocks1] = useState(true);
  const [bodyStyleLocks2, setBodyStyleLocks2] = useState(false);
  const [bodyStyleLocks3, setBodyStyleLocks3] = useState(false);
  const [bodyStyleLocks4, setBodyStyleLocks4] = useState(false);
  const [bodyStyleLocks5, setBodyStyleLocks5] = useState(false);
  const [bodyStyleLocks6, setBodyStyleLocks6] = useState(false);
  const [bodyStyleLocks7, setBodyStyleLocks7] = useState(false);
  const [bodyStyleLocks8, setBodyStyleLocks8] = useState(false);
  const [bodyStyleLocks9, setBodyStyleLocks9] = useState(false);
  const [bodyStyleLocks10, setBodyStyleLocks10] = useState(false);
  const [bodyStyleLocks11, setBodyStyleLocks11] = useState(false);
  const [bodyStyleLocks12, setBodyStyleLocks12] = useState(false);
  const [bodyStyleLocks13, setBodyStyleLocks13] = useState(false);
  const [bodyStyleLocks14, setBodyStyleLocks14] = useState(false);
  const [bodyStyleLocks15, setBodyStyleLocks15] = useState(false);
  const [bodyStyleLocks16, setBodyStyleLocks16] = useState(false);
  const [bodyStyleLocks17, setBodyStyleLocks17] = useState(false);
  const [bodyStyleLocks18, setBodyStyleLocks18] = useState(false);
  const [bodyStyleLocks19, setBodyStyleLocks19] = useState(false);
  const [bodyStyleLocks20, setBodyStyleLocks20] = useState(false);
  const [bodyStyleLocks21, setBodyStyleLocks21] = useState(false);
  const [bodyStyleLocks22, setBodyStyleLocks22] = useState(false);
  const [bodyStyleLocks23, setBodyStyleLocks23] = useState(false);
  const [bodyStyleLocks24, setBodyStyleLocks24] = useState(false);

  const versaBrochures = [
    'https://cdn.mediavalet.com/usva/digilock/dct-habdQkqG5ZdeSh0tHw/LyZ9dsWzD0Saa0GagZYvXg/Original/DIGILOCK-VERSA-KEYPAD-PRODUCT-BROCHURE.pdf',
      'https://cdn.mediavalet.com/usva/digilock/NDT46vT6GkmfRjjfCUDZtg/1CVBH_sYF0apY3bFsnSx1Q/Original/DIGILOCK-VERSA-RFID-PRODUCT-BROCHURE.pdf',
      'https://cdn.mediavalet.com/usva/digilock/LfCJerflM0u0n-TAa3gEcA/_xP29ITIsEO1S28bW6LSoQ/Original/DIGILOCK-VERSA-KEYPAD-NETWORK-PRODUCT-BROCHURE.pdf',
      'https://cdn.mediavalet.com/usva/digilock/Vqt-pGrHDk6ujxz6BckM3w/2skW4DWTSUe_OAzUI7xUlA/Original/DIGILOCK-VERSA-RFID-NETWORK-PRODUCT-BROCHURE.pdf'
  ];

  let dsVersaKeypadStandardBottom = 'https://cdn.mediavalet.com/usva/digilock/tu1JefkLI0SbMkjqPtTlsw/2SPB5EbJNEKK32V6x7USfw/Original/DS-D6VK-X-3-0XB-DEN.pdf';
  let dsVersaKeypadStandardTop = 'https://cdn.mediavalet.com/usva/digilock/FLG4-rBrx0GqOrCN5c_pzQ/pI6qmMgX8EqjOHTbc08Z8w/Original/DS-D6VK-X-3-0XT-DEN.pdf';
  let dsVersaKeypadStandardMiniBottom = 'https://cdn.mediavalet.com/usva/digilock/9MS3OJdSKEOs8NQNS6Y1eg/X53L0Y9xt0C6VpRVdSo8uQ/Original/DS-D6VK-X-5-0XB-UEN.pdf';
  let dsVersaKeypadStandardMiniTop = 'https://cdn.mediavalet.com/usva/digilock/W41lZXd5N0-HugzHDrgGGg/3-3TI2iUkUSEfPYAOmZv5g/Original/DS-D6VK-X-5-0XT-UEN.pdf';
  let dsVersaKeypadHorizontalLeft = 'https://cdn.mediavalet.com/usva/digilock/zkN40tKgZECwtlH-Tw3Q2g/F4_Q_5h3fUa9snFbObl5GQ/Original/DS-D6VK-X-3-0XL-DEN.pdf';
  let dsVersaKeypadHorizontalRight = 'https://cdn.mediavalet.com/usva/digilock/9WsjapsSxEWfNU9FyW40sA/6wtN6jKAokuDJNivAY3fEg/Original/DS-D6VK-X-3-0XR-DEN.pdf';
  let dsVersaKeypadHorizontalMiniLeft = 'https://cdn.mediavalet.com/usva/digilock/q39fWRblHkG1iB2Wja9iQg/NgiQuE3WhEexKOTko_ZyAg/Original/DS-D6VK-X-5-0XL-UEN.pdf';
  let dsVersaKeypadHorizontalMiniRight = 'https://cdn.mediavalet.com/usva/digilock/_pzbhO5lkkixdvE-AeNk9A/7Z5wR1ZCSE60VJcDgS-8PA/Original/DS-D6VK-X-5-0XR-UEN.pdf';
  let dsVersaRFIDStandardBottom = 'https://cdn.mediavalet.com/usva/digilock/vBX2nOCR7027ZbpPcyk6NA/G8gHrh6vX0-ABDP35OZddg/Original/DS-D6VR-X-3-0XN-DEN.pdf';
  let dsVersaRFIDStandardTop = 'https://cdn.mediavalet.com/usva/digilock/qjmwSIiy3kaluWrjdonS9A/4SeDOaZOL0uTD3iwaHWdFg/Original/DS-D6VR-X-3-0XT-DEN.pdf';
  let dsVersaKeypadSmartBottom = 'https://cdn.mediavalet.com/usva/digilock/ySOhZkxjgEePiZwp4PnSrQ/j1KHzKAiq0exQzIei1X-8g/Original/DS-D6VK-N-1-0XB-DEN.pdf';
  let dsVersaKeypadSmartTop = 'https://cdn.mediavalet.com/usva/digilock/x7wUWFCJ0EWRQq2qkT5s2Q/g3L1n7LPoE6VmYuJqJQ_nQ/Original/DS-D6VK-N-1-0XT-DEN.pdf';
  let dsVersaKeypadSmartLeft = 'https://cdn.mediavalet.com/usva/digilock/REVxNbVKC0-avJa33bGe0w/FPuQP_zKpUmr-6Z71cpV1w/Original/DS-D6VK-N-1-0XL-DEN.pdf';
  let dsVersaKeypadSmartRight = 'https://cdn.mediavalet.com/usva/digilock/bHwfo8YpekuDdyxbx4FjGg/fQqwCq9GpEmQGNLkeCe1Hg/Original/DS-D6VK-N-1-0XR-DEN.pdf';

  let dsVersaKeypadRfidBottom = 'https://cdn.mediavalet.com/usva/digilock/emtrhWCBKEizVW9leBuXjg/WQGUIs31-0CtQOSfd9CEXQ/Original/DS-D6VR-N-1-0XN-DEN.pdf';
  let dsVersaKeypadRfidTop = 'https://cdn.mediavalet.com/usva/digilock/9hcK1Sh3i0SfnyJBK_X9_A/gWm6lZD4i0WD0LmmzogV9A/Original/DS-D6VR-N-1-0XT-DEN.pdf';
  let dsVersaRfidMiniBottom = 'https://cdn.mediavalet.com/usva/digilock/m0miFkB6Hk-GeJmWA_3Rxw/qA8DOBhWxkawoOPdODBaog/Original/DS-D6VR-X-2-0XN-EEN.pdf';
  let dsVersaRfidMiniTop = 'https://cdn.mediavalet.com/usva/digilock/pTg60MgJNk6z4HCZqF8XmQ/46rkJBldV0yvHue3Z4hWCg/Original/DS-D6VR-X-2-0XT-AEN.pdf';

  let [brochure, setBrochure] = useState(versaBrochures[0]);
  let [dataSheet, setDataSheet] = useState(dsVersaKeypadStandardBottom);

  const bodyStyleLabels = [
    t('versa_label_1'),
    t('versa_label_2'),
    t('versa_label_3'),
    t('versa_label_4'),
    t('versa_label_5'),
    t('versa_label_6'),
    t('versa_label_7'),
    t('versa_label_8'),
    t('versa_label_9'),
    t('versa_label_10'),
    t('versa_label_11'),
    t('versa_label_12'),
    t('versa_label_13'),
    t('versa_label_14'),
    t('versa_label_15'),
    t('versa_label_16'),
    t('versa_label_17'),
    t('versa_label_18'),
    t('versa_label_19'),
    t('versa_label_20'),
    t('versa_label_21'),
    t('versa_label_22'),
    t('versa_label_24'),
    t('versa_label_25')
  ];

  const [bodyStyleLabel, setBodyStyleLabel] = useState(bodyStyleLabels[0]);

  const handleBodyStyleClick = (e) => {
    let data = e.target.getAttribute('data-value');
    resetLockImages();
    console.log('data: '+data);
    switch (data) {
      case 'one':
        setBodyStyleLocks1(true);
        setBodyStyleLabel(bodyStyleLabels[0]);
        setDataSheet(dsVersaKeypadStandardBottom);
        break;
      case 'two':
        setBodyStyleLocks2(true);
        setBodyStyleLabel(bodyStyleLabels[1]);
        setDataSheet(dsVersaKeypadStandardTop);
        break;
      case 'three':
        setBodyStyleLocks3(true);
        setBodyStyleLabel(bodyStyleLabels[2]);
        setDataSheet(dsVersaKeypadStandardMiniBottom);
        break;
      case 'four':
        setBodyStyleLocks4(true);
        setBodyStyleLabel(bodyStyleLabels[3]);
        setDataSheet(dsVersaKeypadStandardMiniTop);
        break;
      case 'five':
        setBodyStyleLocks5(true);
        setBodyStyleLabel(bodyStyleLabels[4]);
        setDataSheet(dsVersaKeypadHorizontalLeft);
        break;
      case 'six':
        setBodyStyleLocks6(true);
        setBodyStyleLabel(bodyStyleLabels[5]);
        setDataSheet(dsVersaKeypadHorizontalRight);
        break;
      case 'seven':
        setBodyStyleLocks7(true);
        setBodyStyleLabel(bodyStyleLabels[6]);
        setDataSheet(dsVersaKeypadHorizontalMiniLeft);
        break;
      case 'eight':
        setBodyStyleLocks8(true);
        setBodyStyleLabel(bodyStyleLabels[7]);
        setDataSheet(dsVersaKeypadHorizontalMiniRight);
        break;
      case 'nine':
        setBodyStyleLocks9(true);
        setBodyStyleLabel(bodyStyleLabels[8]);
        setDataSheet(dsVersaRFIDStandardBottom);
        break;
      case 'ten':
        setBodyStyleLocks10(true);
        setBodyStyleLabel(bodyStyleLabels[9]);
        setDataSheet(dsVersaRFIDStandardTop);
        break;
      case 'eleven':
        setBodyStyleLocks11(true);
        setBodyStyleLabel(bodyStyleLabels[10]);
        setDataSheet(dsVersaRfidMiniBottom);
        break;
      case 'twelve':
        setBodyStyleLocks12(true);
        setBodyStyleLabel(bodyStyleLabels[11]);
        setDataSheet(dsVersaRfidMiniTop);
        break;
      case 'thirteen':
        setBodyStyleLocks13(true);
        setBodyStyleLabel(bodyStyleLabels[12]);
        setDataSheet(dsVersaRFIDStandardBottom);
        break;
      case 'fourteen':
        setBodyStyleLocks14(true);
        setBodyStyleLabel(bodyStyleLabels[13]);
        setDataSheet(dsVersaRFIDStandardBottom);
        break;
      case 'fifteen':
        setBodyStyleLocks15(true);
        setBodyStyleLabel(bodyStyleLabels[14]);
        setDataSheet(dsVersaRfidMiniBottom);
        break;
      case 'sixteen':
        setBodyStyleLocks16(true);
        setBodyStyleLabel(bodyStyleLabels[15]);
        setDataSheet(dsVersaRfidMiniBottom);
        break;
      case 'seventeen':
        setBodyStyleLocks17(true);
        setBodyStyleLabel(bodyStyleLabels[16]);
        setDataSheet(dsVersaKeypadSmartBottom);
        break;
      case 'eighteen':
        setBodyStyleLocks18(true);
        setBodyStyleLabel(bodyStyleLabels[17]);
        setDataSheet(dsVersaKeypadSmartTop);
        break;
      case 'nineteen':
        setBodyStyleLocks19(true);
        setBodyStyleLabel(bodyStyleLabels[18]);
        setDataSheet(dsVersaKeypadSmartLeft);
        break;
      case 'twenty':
        setBodyStyleLocks20(true);
        setBodyStyleLabel(bodyStyleLabels[19]);
        setDataSheet(dsVersaKeypadSmartRight);
        break;
      case 'twenty-one':
        setBodyStyleLocks21(true);
        setBodyStyleLabel(bodyStyleLabels[20]);
        setDataSheet(dsVersaKeypadRfidBottom);
        break;
      case 'twenty-two':
        setBodyStyleLocks22(true);
        setBodyStyleLabel(bodyStyleLabels[21]);
        setDataSheet(dsVersaKeypadRfidTop);
        break;
      case 'twenty-three':
        setBodyStyleLocks23(true);
        setBodyStyleLabel(bodyStyleLabels[22]);
        setDataSheet(dsVersaKeypadRfidBottom);
        break;
      default:
        setBodyStyleLocks24(true);
        setBodyStyleLabel(bodyStyleLabels[23]);
        setDataSheet(dsVersaKeypadRfidBottom);
    }
  }

  const resetLockImages = (e) => {
    setBodyStyleLocks1(false);
    setBodyStyleLocks2(false);
    setBodyStyleLocks3(false);
    setBodyStyleLocks4(false);
    setBodyStyleLocks5(false);
    setBodyStyleLocks6(false);
    setBodyStyleLocks7(false);
    setBodyStyleLocks8(false);
    setBodyStyleLocks9(false);
    setBodyStyleLocks10(false);
    setBodyStyleLocks11(false);
    setBodyStyleLocks12(false);
    setBodyStyleLocks13(false);
    setBodyStyleLocks14(false);
    setBodyStyleLocks15(false);
    setBodyStyleLocks16(false);
    setBodyStyleLocks17(false);
    setBodyStyleLocks18(false);
    setBodyStyleLocks19(false);
    setBodyStyleLocks20(false);
    setBodyStyleLocks21(false);
    setBodyStyleLocks22(false);
    setBodyStyleLocks23(false);
    setBodyStyleLocks24(false);
  }

  const resetInterfaces = (e) => {
    setIsKeypadSelected(false);
    setIsRfidSelected(false);
    setIsSmartphoneKeypadSelected(false);
    setIsSmartphoneRfidSelected(false);
  }

  const handleInterface = (e) => {
    let data = e.target.getAttribute('data-value');
    resetLockImages();
    resetInterfaces();
    switch (data) {
      case 'rfid':
        setIsRfidSelected(true);
        setBodyStyleLocks9(true);
        setBodyStyleLabel(bodyStyleLabels[8]);
        setBrochure(versaBrochures[1]);
        setDataSheet(dsVersaRFIDStandardBottom);
        break;
      case 'keypad':
        setIsKeypadSelected(true);
        setBodyStyleLocks1(true);
        setBodyStyleLabel(bodyStyleLabels[0]);
        setBrochure(versaBrochures[0]);
        setDataSheet(dsVersaKeypadStandardBottom);
        break;
      case 'smartphone-keypad':
        setIsSmartphoneKeypadSelected(true);
        setBodyStyleLocks17(true);
        setBodyStyleLabel(bodyStyleLabels[16]);
        setBrochure(versaBrochures[2]);
        setDataSheet(dsVersaKeypadSmartBottom);
        break;
      default: //smartphone rfid
        setIsSmartphoneRfidSelected(true);
        setBodyStyleLocks21(true);
        setBodyStyleLabel(bodyStyleLabels[20]);
        setBrochure(versaBrochures[3]);
        setDataSheet(dsVersaKeypadRfidBottom);
    }
  }

  useEffect(() => {
    // code
  }, [])


  return (
      <Layout>
        <Seo
            title={t('versa_title_tag')}
            description={t('versa_desc')}
        />
        <div className="products versa-page">
          <section className="hero-pages">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <div>
                    <StaticImage
                        src="../../../../images/locks/product-pages/versa/logo-gray-versa.png"
                        loading={'lazy'}
                        width={162}
                        height={50}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Versa logo"
                    />
                    <h1>
                      <Trans>versa_headline</Trans>
                    </h1>
                    <p>
                      <Trans>
                        versa_headline_copy
                      </Trans>
                    </p>
                    <BookConsultation/>
                  </div>
                </div>
                <div className="grid-two-col-item-single">
                  <div>
                    <StaticImage
                        src="../../../../images/locks/product-pages/versa/versa-family.png"
                        loading={'lazy'}
                        width={423}
                        height={363}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Versa famiily"
                        className={'cleo-family'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id="pivot" class="section-delimeter container"/>
          <section className={'appearance-functionality versa'}>
            <div className="container">
              <h1 className={'headline'} dangerouslySetInnerHTML={{__html: t('versa_interface_headline')}}/>
              <div className="grid-two-col">
                <div className="grid-two-col-item-single center locks-container">
                  <div className="locks revealer versa">
                    <menu className="interfaces">
                      <button
                          data-value={'rfid'}
                          onClick={handleInterface}
                          className={isRfidSelected ? 'selected' : ''}>
                        RFID<span/>
                      </button>
                      <button
                          data-value={'keypad'}
                          onClick={handleInterface}
                          className={isKeypadSelected ? 'selected' : ''}>
                        Keypad<span/>
                      </button>
                      <button
                          data-value={'smartphone-keypad'}
                          onClick={handleInterface}
                          className={isSmartphoneKeypadSelected ? 'selected' : ''}>
                        Smartphone/<br/>Keypad<span/>
                      </button>
                      <button
                          data-value={'smartphone-rfid'}
                          onClick={handleInterface}
                          className={isSmartphoneRfidSelected ? 'selected' : ''}>
                        Smartphone/<br/>RFID<span/>
                      </button>
                    </menu>
                    <div className={isKeypadSelected ? 'icons show' : 'icons'}>
                      <div>
                        <img src={bodyStyleLocks1 ? iconVertStndBotKeyOn : iconVertStndBotKey}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'one'}
                             width={'17'}
                             height={'74'}
                             alt="Vertical Standard Bottom Keypad"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks2 ? iconVertStndTopKeyOn : iconVertStndTopKey}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'two'}
                             width={'17'}
                             height={'74'}
                             alt="Vertical Standard Top Keypad"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks3 ? iconVertMiniBotKeyOn : iconVertMiniBotKey}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'three'}
                             width={'14'}
                             height={'61'}
                             alt="Vertical Mini Bottom Keypad"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks4 ? iconVertMiniTopKeyOn : iconVertMiniTopKey}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'four'}
                             width={'14'}
                             height={'61'}
                             alt="Vertical Mini Top Keypad"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks5 ? iconHorizStndLeftKeyOn : iconHorizStndLeftKey}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'five'}
                             width={'74'}
                             height={'17'}
                             alt="Horizontal Standard Left Keypad"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks6 ? iconHorizStndRightKeyOn : iconHorizStndRightKey}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'six'}
                             width={'74'}
                             height={'17'}
                             alt="Horizontal Standard Right Keypad"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks7 ? iconHorizMiniLeftKeyOn : iconHorizMiniLeftKey}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'seven'}
                             width={'61'}
                             height={'14'}
                             alt="Horizontal Mini Left Keypad"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks8 ? iconHorizMiniRightKeyOn : iconHorizMiniRightKey}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'eight'}
                             width={'61'}
                             height={'14'}
                             alt="Horizontal Mini Right Keypad"/>
                      </div>
                    </div>
                    <div className={isRfidSelected ? 'icons show' : 'icons'}>
                      <div>
                        <img src={bodyStyleLocks9 ? iconVertStndBotRfidOn : iconVertStndBotRfid}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'nine'}
                             width={'17'}
                             height={'74'}
                             alt="Vertical Standard Bottom RFID"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks10 ? iconVertStndTopRfidOn : iconVertStndTopRfid}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'ten'}
                             width={'17'}
                             height={'74'}
                             alt="Vertical Standard Top RFID"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks11 ? iconVertMiniBotRfidOn : iconVertMiniBotRfid}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'eleven'}
                             width={'14'}
                             height={'61'}
                             alt="Vertical Mini Bottom RFID"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks12 ? iconVertMiniTopRfidOn : iconVertMiniTopRfid}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'twelve'}
                             width={'14'}
                             height={'61'}
                             alt="Vertical Mini Top RFID"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks13 ? iconHorizStndLeftRfidOn : iconHorizStndLeftRfid}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'thirteen'}
                             width={'74'}
                             height={'17'}
                             alt="Horizontal Standard Left RFID"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks14 ? iconHorizStndRightRfidOn : iconHorizStndRightRfid}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'fourteen'}
                             width={'74'}
                             height={'17'}
                             alt="Horizontal Standard Right RFID"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks15 ? iconHorizMiniLeftRfidOn : iconHorizMiniLeftRfid}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'fifteen'}
                             width={'61'}
                             height={'14'}
                             alt="Horizontal Mini Left RFID"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks16 ? iconHorizMiniRightRfidOn : iconHorizMiniRightRfid}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'sixteen'}
                             width={'61'}
                             height={'14'}
                             alt="Horizontal Mini Right RFID"/>
                      </div>
                    </div>
                    <div className={isSmartphoneKeypadSelected ? 'icons show' : 'icons'}>
                      <div>
                        <img src={bodyStyleLocks17 ? iconVertHybridBotKeyOn : iconVertHybridBotKey}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'seventeen'}
                             width={'14'}
                             height={'76'}
                             alt="Vertical Hybrid Bottom Key"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks18 ? iconVertHybridTopKeyOn : iconVertHybridTopKey}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'eighteen'}
                             width={'14'}
                             height={'76'}
                             alt="Vertical Hybrid Top Keypad"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks19 ? iconHorizHybridLeftKeyOn : iconHorizHybridLeftKey}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'nineteen'}
                             width={'76'}
                             height={'14'}
                             alt="Horizontal Hybrid Left Keypad"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks20 ? iconHorizHybridRightKeyOn : iconHorizHybridRightKey}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'twenty'}
                             width={'76'}
                             height={'14'}
                             alt="Vertical Mini Top RFID"/>
                      </div>
                    </div>
                    <div className={isSmartphoneRfidSelected ? 'icons show' : 'icons'}>
                      <div>
                        <img src={bodyStyleLocks21 ? iconVertHybridBotRfidOn : iconVertHybridBotRfid}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'twenty-one'}
                             width={'14'}
                             height={'76'}
                             alt="Vertical Hybrid Bottom RFID"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks22 ? iconVertHybridTopRfidOn : iconVertHybridTopRfid}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'twenty-two'}
                             width={'14'}
                             height={'76'}
                             alt="Vertical Hybrid Top RFID"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks23 ? iconHorizHybridLeftRfidOn : iconHorizHybridLeftRfid}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'twenty-three'}
                             width={'76'}
                             height={'14'}
                             alt="Horizontal Hybrid Left RFID"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocks24 ? iconHorizHybridRightRfidOn : iconHorizHybridRightRfid}
                             className={'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'twenty-four'}
                             width={'76'}
                             height={'14'}
                             alt="Vertical Mini Top RFID"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-two-col-item-single center locks-container">
                  <div className="locks">
                    <div className={'body-locks-container'}>
                      <StaticImage
                          className={bodyStyleLocks1 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/vert-stnd-key-bot.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Standard Keypad Bottom"
                      />
                      <StaticImage
                          className={bodyStyleLocks2 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/vert-stnd-key-top.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Standard Keypad Top"
                      />
                      <StaticImage
                          className={bodyStyleLocks3 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/vert-mini-key-bot.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Mini Keypad Bottom"
                      />
                      <StaticImage
                          className={bodyStyleLocks4 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/vert-mini-key-top.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Mini Keypad Top"
                      />
                      <StaticImage
                          className={bodyStyleLocks5 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/horiz-stnd-key-left.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Standard Horizontal Keypad Left"
                      />
                      <StaticImage
                          className={bodyStyleLocks6 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/horiz-stnd-key-right.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Standard Horizontal Keypad Right"
                      />
                      <StaticImage
                          className={bodyStyleLocks7 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/horiz-mini-key-left.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Mini Horizontal Keypad Left"
                      />
                      <StaticImage
                          className={bodyStyleLocks8 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/horiz-mini-key-right.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Mini Horizontal Keypad Right"
                      />
                      <StaticImage
                          className={bodyStyleLocks9 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/vert-stnd-rfid-bot.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Standard RFID Bottom"
                      />
                      <StaticImage
                          className={bodyStyleLocks10 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/vert-stnd-rfid-top.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Standard RFID Top"
                      />
                      <StaticImage
                          className={bodyStyleLocks11 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/vert-mini-rfid-bot.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Mini RFID Bottom"
                      />
                      <StaticImage
                          className={bodyStyleLocks12 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/vert-mini-rfid-top.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Mini RFID Top"
                      />
                      <StaticImage
                          className={bodyStyleLocks13 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/horiz-stnd-rfid-left.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Standard Horizontal RFID Left"
                      />
                      <StaticImage
                          className={bodyStyleLocks14 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/horiz-stnd-rfid-right.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Standard Horizontal RFID Right"
                      />
                      <StaticImage
                          className={bodyStyleLocks15 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/horiz-mini-rfid-left.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Mini Horizontal RFID Left"
                      />
                      <StaticImage
                          className={bodyStyleLocks16 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/horiz-mini-rfid-right.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Mini Horizontal RFID Right"
                      />
                      <StaticImage
                          className={bodyStyleLocks17 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/vert-hybrid-key-bot.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Hybrid Keypad Bottom"
                      />
                      <StaticImage
                          className={bodyStyleLocks18 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/vert-hybrid-key-top.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Hybrid Keypad Top"
                      />
                      <StaticImage
                          className={bodyStyleLocks19 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/horiz-hybrid-key-left.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Hybrid Horizontal Keypad Left"
                      />
                      <StaticImage
                          className={bodyStyleLocks20 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/horiz-hybrid-key-right.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Hybrid Horizontal Keypad Right"
                      />
                      <StaticImage
                          className={bodyStyleLocks21 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/vert-hybrid-rfid-bottom.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Hybrid RFID Bottom"
                      />
                      <StaticImage
                          className={bodyStyleLocks22 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/vert-hybrid-rfid-top.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Hybrid RFID Bottom"
                      />
                      <StaticImage
                          className={bodyStyleLocks23 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/horiz-hybrid-rfid-left.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Hybrid Horizontal RFID Left"
                      />
                      <StaticImage
                          className={bodyStyleLocks24 ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/versa/horiz-hybrid-rfid-right.png"
                          loading={'lazy'}
                          width={411}
                          height={412}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Versa Hybrid Horizontal RFID Right"
                      />

                    </div>
                    <ProductDocuments productName={'versa'}
                        brochure={brochure}
                        brochureLabel={t('download_brochure')}
                        dataSheet={dataSheet}
                        dataSheetLabel={t('download_datasheet')}
                        lockLabel={bodyStyleLabel}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="section-delimeter container"/>
          <section className="page-benefits">
            <div className="container">
              <h1>
                <Trans>options_to_suit</Trans>
              </h1>
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <div className="parent">
                    <div>
                      <h3 dangerouslySetInnerHTML={{__html: t('product_highlights')}}/>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('All_metal_housing')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Advanced_auto_unlock')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Visual_and_audible_indicators')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('mech_ada')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('IP55_rated,_FCC,_CE_and_IC_certified')}}/>
                      </ul>
                      <h3 dangerouslySetInnerHTML={{__html: t('Access_Options')}}/>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('keypad')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('rfid')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Mobile_Access')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('elec_key')}}/>
                      </ul>
                    </div>
                    <div>
                      <h3><Trans>cleo_functionality</Trans></h3>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('Shared_Use_Mode')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Assigned_Use_Mode')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Reservation_Mode') + '<br />(' + t('networked_locks_only') + ')'}}/>
                      </ul>
                      <h3><Trans>cleo_installation</Trans></h3>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_installation_list_1')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_installation_list_3')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('90_or_180_knob_rotation,_clockwise_or_counter_clockwise_to_unlock')}}/>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={'locking-finish-items'}>

                  <div>
                    <h3><Trans>finish_options</Trans></h3>
                    <div className="thumbs">
                      <div>
                        <img
                            src={swatchBrushed}
                            width={84}
                            height={84}
                            alt="brushed nickel finish"/>
                        <p className={'curve-swatch'}><Trans>brushed_nickel</Trans></p>
                      </div>
                      <div>

                        <img
                            src={swatchBlack}
                            width={84}
                            height={84}
                            alt="matte black finish"/>
                        <p className={'curve-swatch'}><Trans>matte_black</Trans></p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="cleo-locking-options">
            <div class="container">

              <div>
                <h3><Trans>locking_options</Trans></h3>
                <div className="grid-four-col">
                  <div>
                    <img src={flatCam} alt="flat cam" width={'117'} height={'92'}/>
                    <p className={'curve-deadlatch'}><Trans>flat_cam</Trans></p>
                  </div>
                  <div>
                    <img src={lifterCam} alt="lifter cam" width={'117'} height={'92'}/>
                    <p className={'curve-deadlatch'}><Trans>lifter_cam</Trans></p>
                  </div>

                  <div>
                    <img src={offsetCam} alt="offset cam" width={'117'} height={'92'}/>
                    <p className={'curve-deadlatch'}><Trans>offset_cam</Trans></p>
                  </div>
                  <div>
                    <img src={slamCam} alt="slam cam" width={'117'} height={'92'}/>
                    <p className={'curve-deadlatch'}><Trans>slam_cam</Trans><br/>(<Trans>assigned_use_only</Trans>)</p>
                  </div>
                </div>
              </div>
              <br/><br/>
              <BookConsultation/>
            </div>
          </section>
          <div id="pivot" className="section-delimeter container"/>
          <section className="page-management">
            <BANManagement product={'Versa'}/>
          </section>
          <div id="pivot" className="section-delimeter container"/>
          <section className="faq-section">
            <FaqSection/>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
